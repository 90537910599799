import React from "react";
import { useState, useEffect } from "react";
import styles from "./welcomeSection.module.css";
import { RouterLink } from "mobx-state-router";
import AnimationPath from "../../../../media/images/path.svg";
import { inject, observer } from "mobx-react";
import ScrollAnimation from "react-animate-on-scroll";
import Poster from "../../../../media/images/poster.png";
import { trackHomepageVideoFunctions } from "../../../../util/util";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Animation from "-!svg-react-loader!./animation.svg";

@inject("rootStore")
@observer
class WelcomeSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { y_position: 0 };
  }
  handleScrollPosition = () => {
    this.setState({
      y_position: window.pageYOffset,
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScrollPosition);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollPosition);
  }

  changeDecorationDiv = () => {
    let scrollPosition = this.state.y_position;
    if (scrollPosition < 745) {
      return styles.dot1;
    } else if (scrollPosition < 900) {
      return styles.dot2;
    } else {
      return styles.dot3;
    }
  };

  render() {
    let videoUrl =
      process.env.REACT_APP_ENV == "production"
        ? "https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4"
        : "https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4";

    return (
      <div id="Welcome" className={styles.welcome_section_wrap}>
        <span className={styles.icon1}></span>
        <span className={styles.icon2}></span>
        <div className="container">
          <div
            style={{
              border: "1px solid #fff",
              padding: "10px 5px 5px",
              borderRadius: "5px",
              marginBottom: "30px",
              zIndex: "999", 
              position: "relative"
            }}
          >
            <p className="mb-1 mt-1">
              <strong>
                This website is intended for use by Healthcare Professionals
                only.
              </strong>
            </p>
            <p>
              {" "}
              <strong>
                This promotional meeting is organised and funded by Teva
                Pharmaceuticals Europe B.V.
              </strong>
            </p>
            <p  >
              Some sessions may contain product information. <br />
              For the fremanezumab UK
              prescribing information and adverse events reporting, please see
              footer of the page.
              <br />
            </p>
          </div>

          <h2>
            We look forward to welcoming you in <strong>Berlin</strong> this
            year, for our returning <strong>face-to-face</strong> Migraine Headache
            Management (MHM) meeting! <br />
            <br />
            <strong>
              {" "}
              <RouterLink routeName="Contact"> CONTACT US</RouterLink>
            </strong>{" "}
            <strong>to secure your spot for 4 and 5 October 2024.</strong>
            <br />
            <br />
          </h2>

          <p>
            <strong>MHM 2024: Patients, Treatments, Choices</strong> will take place across{" "}
            <strong>two half-days </strong>and will provide you with a
            space to:
          </p>
          <div className="row justify-content-center">
            <div className="col-12 col-md-9 ">
              <ul style={{ color: "#f89410" }} className="text-left">
                <li>
                  Gain expert insights on recent advances in the field
                </li>
                <li>
                  Learn how the latest evidence can improve practice and inform decision-making
                </li>
                <li>
                  Exchange ideas with colleagues and participate in interactive breakout sessions 
                </li>
                <li>
                  Explore different approaches to common clinical scenarios
                </li>
              </ul>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-9 text-left">
              <p style={{ marginTop: "10px" }}>
                <strong>
                  <span style={{ fontSize: "25px" }}>Date: Friday 4 and Saturday 5 October 2024</span><br />
                  Location: JW Marriott Hotel Berlin, Stauffenbergstraße 26, 10785 Berlin, Germany<br />
                </strong>
                <strong>Parking: </strong>
                The Hotel offers an underground garage on property with more than 200 parking spots.
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19426.241660826156!2d13.3525637!3d52.5102679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a851fe88220039%3A0xf83e03f9a70b3368!2sJW%20Marriott%20Hotel%20Berlin!5e0!3m2!1sen!2ske!4v1713438787828!5m2!1sen!2ske"
                style={{ border: 0, width: "100%", minHeight: "300px", marginBottom: "20px" }}
                allowfullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Location Map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomeSection;
