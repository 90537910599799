import React from "react";
import {RouterState} from "mobx-state-router";
import NotFound from "./views/not-found";
import {isEmptyString} from "./util/util";
import { checkIfAuthenticated, checkIfAuthenticatedAsAdmin, redirectIfLoggedIn } from "./util/routeHelper";
import {userAttendedPartOneEvent} from "./controllers/events-controller"
import AppWithAuth from "./views/auth-forms/auth-form";
import AppEntry from "./views";
import Admin from "./views/admin/Admin";
import ManageUser from "./views/admin/manage-user";
import Register from "./views/register";
import SelectedEvent from "./views/admin/Event";
import EditProfile from "./views/editProfile";
import MyProfile from "./views/myProfile";
import PrivacyStatement from "./views/privacy-statement";
import Cookies from "./views/cookies";
import Cme from "./views/post-registration/cme/cme"; 
import SteeringCommittee from "./views/public/steeringCommittee/steeringcommittee";
import Contact from "./views/public/contact/contact";
import Programme from "./views/public/programme/programme";
import PracticalWorkshop from "./views/post-registration/practical-workshop/practicalWorkshop";
import FacultyPage from "./views/post-registration/faculty/faculty";
import MigraineEducationOnDemand from "./views/post-registration/migraine-education-on-demand/migraine-education-on-demand";
import ResetPassword from "./views/auth-forms/reset-password";
import SetNewPassword from "./views/auth-forms/set-new-password";
import EventBookingThankYou from "./views/event-booking-thank-you";
import RegisterThankYou from "./views/registration-thank-you"
import MassEmail from './views/admin/mass-email/mass-email'
import PreviewEmail from './views/admin/mass-email/preview-email'
import Auth from "@aws-amplify/auth";
////routes constants
//the screen with routePathPattern of '/'
export const DEFAULT_ROUTE = {
    view: <AppEntry/>,
    routeName: AppEntry.routeName,
    routePathPattern: AppEntry.routePathPattern,
};



//the immediate screen after authentication
export const HOME_ROUTE = {
    view: <AppEntry/>,
    routeName: AppEntry.routeName,
    routePathPattern: AppEntry.routePathPattern,
};
export const LOGIN_ROUTE = {
    view: <AppWithAuth/>,
    routeName: AppWithAuth.routeName,
    routePathPattern: AppWithAuth.routePathPattern,
};
//the other routes
export const ADMINISTRATION_WIZARD_ROUTE = {
    view: <Admin/>,
    routeName: Admin.routeName,
    routePathPattern: Admin.routePathPattern,
};


export const RESET_PASSWORD_ROUTE = {
    view: <ResetPassword />,
    routeName: ResetPassword.routeName,
    routePathPattern: ResetPassword.routePathPattern
}
export const SET_NEW_PASSWORD_ROUTE = {
    view: <SetNewPassword />,
    routeName: SetNewPassword.routeName,
    routePathPattern: SetNewPassword.routePathPattern
}


export const  REGISTER_ROUTE = {
    view: <Register/>,
    routeName: Register.routeName,
    routePathPattern: Register.routePathPattern,
}
export const EDIT_PROFILE_ROUTE ={
    view: <EditProfile/>,
    routeName: EditProfile.routeName,
    routePathPattern: EditProfile.routePathPattern,
}
export const MY_PROFILE_ROUTE = {
    view: <MyProfile />,
    routeName: MyProfile.routeName,
    routePathPattern: MyProfile.routePathPattern,
}


export const  PRIVACY_STATEMENT_ROUTE = {
    view: <PrivacyStatement/>,
    routeName: PrivacyStatement.routeName,
    routePathPattern: PrivacyStatement.routePathPattern,
}


export const  COOKIES_ROUTE= {
    view: <Cookies/>,
    routeName: Cookies.routeName,
    routePathPattern: Cookies.routePathPattern,
}
export const  FACULTY_ROUTE= {
    view: <FacultyPage/>,
    routeName: FacultyPage.routeName,
    routePathPattern: FacultyPage.routePathPattern,
}
export const  MIGRAINE_EDUCATION_ON_DEMAND_ROUTE= {
    view: <MigraineEducationOnDemand/>,
    routeName: MigraineEducationOnDemand.routeName,
    routePathPattern: MigraineEducationOnDemand.routePathPattern,
}
export const  CME_ROUTE = {
    view: <Cme/>,
    routeName: Cme.routeName,
    routePathPattern: Cme.routePathPattern,
}
export const STEERING_COMMITTEE_ROUTE = {
    view: <SteeringCommittee />,
    routeName: SteeringCommittee.routeName,
    routePathPattern: SteeringCommittee.routePathPattern,
}
export const CONTACT_ROUTE = {
    view: <Contact />,
    routeName: Contact.routeName,
    routePathPattern: Contact.routePathPattern,
}
export const PROGRAMME_ROUTE = {
    view: <Programme />,
    routeName: Programme.routeName,
    routePathPattern: Programme.routePathPattern,
}
export const PRACTICAL_WORKSHOP_ROUTE = {
    view: <PracticalWorkshop />,
    routeName: PracticalWorkshop.routeName,
    routePathPattern: PracticalWorkshop.routePathPattern,
}


export const SELECTED_EVENT_ROUTE = {
    view: <SelectedEvent />,
    routeName: SelectedEvent.routeName,
    routePathPattern: SelectedEvent.routePathPattern
}
export const MANAGE_USER_ROUTE = {
    view: <ManageUser />,
    routeName: ManageUser.routeName,
    routePathPattern: ManageUser.routePathPattern
}
export const MASS_EMAIL_ROUTE = {
    view: <MassEmail />,
    routeName: MassEmail.routeName,
    routePathPattern: MassEmail.routePathPattern
}
export const PREVIEW_UNSAVED_MAIL_ROUTE = {
    view: <PreviewEmail />,
    routeName: PreviewEmail.routeName1,
    routePathPattern: PreviewEmail.routePathPattern1
}
export const PREVIEW_EMAIL_ROUTE = {
    view: <PreviewEmail />,
    routeName: PreviewEmail.routeName,
    routePathPattern: PreviewEmail.routePathPattern
}
export const  EVENT_BOOKING_THANK_YOU_ROUTE = {
    view: <EventBookingThankYou/>,
    routeName: EventBookingThankYou.routeName,
    routePathPattern: EventBookingThankYou.routePathPattern,
}

export const  REGISTER_THANK_YOU_ROUTE = {
    view: <RegisterThankYou/>,
    routeName: RegisterThankYou.routeName,
    routePathPattern: RegisterThankYou.routePathPattern,
}


//and finally the 404 route
export const NOT_FOUND_ROUTE = {
    view: <NotFound/>,
    routeName: NotFound.routeName,
    routePathPattern: NotFound.routePathPattern,
};

//provision for the routes views to be added to the view map
export const views = [
    DEFAULT_ROUTE,
    LOGIN_ROUTE,
    HOME_ROUTE,
    ADMINISTRATION_WIZARD_ROUTE,
    RESET_PASSWORD_ROUTE,
    SET_NEW_PASSWORD_ROUTE,
    NOT_FOUND_ROUTE,
   
    REGISTER_ROUTE,
    EDIT_PROFILE_ROUTE,
    MY_PROFILE_ROUTE,
   
    PRIVACY_STATEMENT_ROUTE,
    COOKIES_ROUTE,
    SELECTED_EVENT_ROUTE,
    MANAGE_USER_ROUTE,
    EVENT_BOOKING_THANK_YOU_ROUTE,
    REGISTER_THANK_YOU_ROUTE,
    CME_ROUTE,
    STEERING_COMMITTEE_ROUTE,
    CONTACT_ROUTE,
    PROGRAMME_ROUTE,
    PRACTICAL_WORKSHOP_ROUTE,
    FACULTY_ROUTE,
    MIGRAINE_EDUCATION_ON_DEMAND_ROUTE,

    MASS_EMAIL_ROUTE,
    PREVIEW_UNSAVED_MAIL_ROUTE,
    PREVIEW_EMAIL_ROUTE
];


export function viewMapBuilder(views) {
    let viewMap = {};
    for (let view of views) {
        let View = view.view;
        viewMap[view.routeName] = !isEmptyString(View.routeName) ? <View/> : View;
    }
    return viewMap;
}

export const routes = [
    {
        name: DEFAULT_ROUTE.routeName,
        pattern: DEFAULT_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
           
        },
        onEnter: (fromState, toState, routerStore) => {
            //in case you needed to access the appStores and act on data
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: LOGIN_ROUTE.routeName,
        pattern: LOGIN_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            const urlParams = new URLSearchParams(window.location.search);
            const profileRedirect = urlParams.get('profileRedirect');
            if(profileRedirect){
                localStorage.setItem("profileRedirect", profileRedirect)
            }
            return redirectIfLoggedIn(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: HOME_ROUTE.routeName,
        pattern: HOME_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
          
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },

    {
        name: ADMINISTRATION_WIZARD_ROUTE.routeName,
        pattern: ADMINISTRATION_WIZARD_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            return checkIfAuthenticatedAsAdmin(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },

    
    {
        name: RESET_PASSWORD_ROUTE.routeName,
        pattern: RESET_PASSWORD_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {

        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore
            return Promise.resolve()
        }
    },
    {
        name: SET_NEW_PASSWORD_ROUTE.routeName,
        pattern: SET_NEW_PASSWORD_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {

        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore
            return Promise.resolve()
        }
    },
   
    {
        name: REGISTER_ROUTE.routeName,
        pattern: REGISTER_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
            routerStore.goTo(HOME_ROUTE.routeName);
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            routerStore.goTo(HOME_ROUTE.routeName);
            return Promise.resolve();
        }
    },

    {
        name: EDIT_PROFILE_ROUTE.routeName,
        pattern: EDIT_PROFILE_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
            // return redirectIfLoggedIn(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: MY_PROFILE_ROUTE.routeName,
        pattern: MY_PROFILE_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
            // return redirectIfLoggedIn(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }
    },
    
    {
        name: PRIVACY_STATEMENT_ROUTE.routeName,
        pattern: PRIVACY_STATEMENT_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
           
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: COOKIES_ROUTE.routeName,
        pattern: COOKIES_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
           
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: FACULTY_ROUTE.routeName,
        pattern: FACULTY_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
            // return checkIfAuthenticated(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: MIGRAINE_EDUCATION_ON_DEMAND_ROUTE.routeName,
        pattern: MIGRAINE_EDUCATION_ON_DEMAND_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
            const {rootStore: {authStore}} = routerStore;
            let isLoggedIn = null;

            try {
                isLoggedIn = await Auth.currentAuthenticatedUser();
            }catch(e){}

            if(!isLoggedIn){
                routerStore.goTo("login");
            }

            let attended = await userAttendedPartOneEvent();
            if(!attended){
                authStore.setSignInRedirect(toState);
                return Promise.reject(new RouterState(HOME_ROUTE.routeName));
            }
            return checkIfAuthenticated(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve(); 
        }
    },
    
    {
        name: CME_ROUTE.routeName,
        pattern: CME_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
           
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: STEERING_COMMITTEE_ROUTE.routeName,
        pattern: STEERING_COMMITTEE_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {

        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }
        
    },
    {
        name: PROGRAMME_ROUTE.routeName,
        pattern: PROGRAMME_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }

    },
    {
        name: PRACTICAL_WORKSHOP_ROUTE.routeName,
        pattern: PRACTICAL_WORKSHOP_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
            routerStore.goTo(HOME_ROUTE.routeName);
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            routerStore.goTo(HOME_ROUTE.routeName);
            return Promise.resolve();
        }

    },

    
    {
        name: CONTACT_ROUTE.routeName,
        pattern: CONTACT_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {

        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }

    },
    {
        name: SELECTED_EVENT_ROUTE.routeName,
        pattern: SELECTED_EVENT_ROUTE.routePathPattern,

        beforeEnter: async (fromState, toState, routerStore) => {
            return checkIfAuthenticatedAsAdmin(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: MANAGE_USER_ROUTE.routeName,
        pattern: MANAGE_USER_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            return checkIfAuthenticatedAsAdmin(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: MASS_EMAIL_ROUTE.routeName,
        pattern: MASS_EMAIL_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            return checkIfAuthenticatedAsAdmin(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: PREVIEW_EMAIL_ROUTE.routeName,
        pattern: PREVIEW_EMAIL_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            return checkIfAuthenticatedAsAdmin(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: PREVIEW_UNSAVED_MAIL_ROUTE.routeName,
        pattern: PREVIEW_UNSAVED_MAIL_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            return checkIfAuthenticatedAsAdmin(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const { rootStore: { appStores, authStore } } = routerStore;
            return Promise.resolve();
        }
    },
    
    
    {
        name: EVENT_BOOKING_THANK_YOU_ROUTE.routeName,
        pattern: EVENT_BOOKING_THANK_YOU_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            return checkIfAuthenticated(fromState, toState, routerStore);
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },

    {
        name: REGISTER_THANK_YOU_ROUTE.routeName,
        pattern: REGISTER_THANK_YOU_ROUTE.routePathPattern,
        beforeEnter: async (fromState, toState, routerStore) => {
            
        },
        onEnter: (fromState, toState, routerStore) => {
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
    {
        name: NOT_FOUND_ROUTE.routeName,
        pattern: NOT_FOUND_ROUTE.routePathPattern,
        beforeEnter: (fromState, toState, routerStore) => {
        },
        onEnter: (fromState, toState, routerStore) => {
            //in case you needed to access the appStores and act on data
            const {rootStore: {appStores, authStore}} = routerStore;
            return Promise.resolve();
        }
    },
];

