import React from 'react';
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import AdminSwitch from '../adminswitch/adminswitch';
import { RouterLink, Router, Link } from 'mobx-state-router';
import { handleLogout } from "../../controllers/app-controller";
import styles from "./header.module.css"
import {userAttendedPartOneEvent} from "../../controllers/events-controller"


@inject("rootStore")
@observer
class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.routerStore =this.props.rootStore.routerStore;
        this.state = {
            isAuthenticated: false,
            showMenu: false,
            dropdownIsActive: false,
            isLoading: true
        }
        
    }
 
    async componentDidMount(){
        let { authStore } = this.props.rootStore;
        let isAuthenticated = await authStore.isAuthenticated();
        this.setState({isAuthenticated, isLoading: false})
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }
    toggleDropdownMenu = () => {
        this.setState({
            dropdownIsActive: !this.state.dropdownIsActive
        })
    }
   

    render() {
        let { isAuthenticated, showMenu, isLoading} = this.state;
        let { authStore } = this.props.rootStore;
        const changeMenuClass = showMenu ? `${styles.show}` : `${styles.hide}`;
        const scrollTop = () =>{
            window.scrollTo({top: 0, behavior: 'smooth'});
         };
        let routeName = this.props.rootStore.routerStore.routerState.routeName;
        return (
           
            <div className={`${styles.header}`}>
                <div className={`${styles.menu} ${changeMenuClass}`}>
                    <div onClick={this.toggleMenu} className={styles.overlay} style={{zIndex:"9"}}></div>
                    <RouterLink onClick={scrollTop} routeName="home"  ><div className={styles.logo}>  </div> </RouterLink>
                    {!isLoading &&
                    <>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-10">
                            <ul className={`${styles.top_menu} hide_for_small` }>
                                <li >
                                    <RouterLink onClick={scrollTop} className={routeName == "Programme" ? "active" : ""} routeName="Programme" >Programme</RouterLink>
                                </li>
                                <li>
                                    <RouterLink onClick={scrollTop}  className={routeName == "steeringCommittee" ? "active" : ""} routeName="steeringCommittee">Steering committee</RouterLink>
                                
                                </li>
                         
                                <li> <RouterLink onClick={scrollTop} className={routeName == "faculty" ? "active" : ""} routeName="faculty">Faculty</RouterLink>    </li>
                                <li> <RouterLink onClick={scrollTop} className={routeName == "cme" ? "active" : ""} routeName="cme">CME</RouterLink>    </li>
                        
                                
                                <li>
                                    <RouterLink onClick={scrollTop} className={routeName == "Contact" ? "active" : ""} routeName="Contact"> Contact</RouterLink>

                                </li>
                            </ul>
                        </div>
                        <ul className={styles.menu_section}>
                        
                            {isAuthenticated && <>
                            <li className={`${styles.register} `}> <RouterLink onClick={scrollTop}  className={`${styles.button}${routeName == "editProfile" ? " active" : ""}`} routeName="editProfile">My profile </RouterLink></li>
                          </>
                            }
                            {isAuthenticated && <li className={`${styles.log_in} ${styles.button} button`} onClick={() => { handleLogout(authStore, this) }}>Log Out</li>}
                            {!isAuthenticated && <li className={styles.log_in}><RouterLink onClick={scrollTop} className={`${styles.button} button`} routeName="login">Log in</RouterLink></li>}
                        <li className={`${styles.menu_bar} m-0 pl-0`}>

                            <ul onClick={this.toggleMenu} className={ `${styles.menu_icon} row`}>
                                <li className="col-8">
                                    <span >Menu</span>
                                </li>
                                <li className="col-4">
                                    <span className={styles.bar1}></span>
                                    <span className={styles.bar2}></span>
                                    <span className={styles.bar3}></span>
                                </li>
                            </ul>

                            {/* begin main nav items */}

                            <div className={styles.main_nav}>
                                <span onClick={this.toggleMenu} className={styles.close_menu}>X</span>
                            <div className={styles.mobile_buttons}>
                                        {isAuthenticated && <RouterLink routeName="editProfile"> <button className="button"> My profile</button> </RouterLink>}
                                    {/* {!isAuthenticated && <button className="button"> <RouterLink routeName="register">Register here</RouterLink></button>} */}
                                    {isAuthenticated && <button className="button" onClick={() => { handleLogout(authStore, this) }}>Log Out</button>}
                                        {!isAuthenticated && <RouterLink onClick={scrollTop} routeName="login"> <button className="button">Log in</button></RouterLink>}
                                    
                            </div>
                                
                            
                                    <ul>
                                    
                                        <li>
                                            <RouterLink onClick={scrollTop} className={routeName == "Programme" ? "active" : ""} routeName="Programme">Programme</RouterLink>

                                        </li>
                                        <li>
                                            <RouterLink onClick={scrollTop} className={routeName == "steeringCommittee" ? "active" : ""} routeName="steeringCommittee">Steering committee</RouterLink>

                                        </li>
                                                            
                                        <li> <RouterLink onClick={scrollTop} className={routeName == "faculty" ? "active" : ""} routeName="faculty">Faculty</RouterLink>    </li>
                                        <li> <RouterLink onClick={scrollTop} className={routeName == "cme" ? "active" : ""} routeName="cme">CME</RouterLink>    </li>
                                        <li>
                                            <RouterLink onClick={scrollTop} className={routeName == "Contact" ? "active" : ""} routeName="Contact">Contact</RouterLink>

                                        </li>
                                    
                                    </ul>

                            </div>


                            {/* end mainnav items */}
                            
                            
                        </li>
                        
                        </ul>
                        <div className={`${styles.adminSwtich} adminSwitch`}>
                            <AdminSwitch />
                        </div>
                    </>
                    }


                   
                </div>

            </div>
        
        );
    }
}

export default Banner;