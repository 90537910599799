import { inject, observer } from "mobx-react";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { getAllEvents, userAttendedPartOneEvent } from "../../../../controllers/events-controller";
// import Banner from "../../../post-registration/home/banner/banner"
import Banner from "../../../public/home/banner/banner";
import styles from "./banner.module.css";
import PracticalWorkshopIcon from "../../../../media/images/mhm2022/practical_workshop.png"
import MasterClassIcon from "../../../../media/images/mhm2022/masterclass.png"
import Forum from "../../../../media/images/mhm2022/forum.png"
import Poster from "../../../../media/images/poster.png"
import {trackHomepageVideoFunctions} from "../../../../util/util"
import OptimalMigraine from "./modals/optimalMigraine/optimalMigraine"
import {Auth} from "aws-amplify";
import { data } from "../../../../data/data";


let plenary = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/MHM+2023_How+is+practice+changing+in+Europe-_PB_FINAL/story.html',
        text: "How is practice changing in Europe?",
        speaker: "Piero Barbanti"
    },
    {
        id: 2,
        src: '',
        text: "What do we know about gepants* so far?",
        speaker: "Patricia Pozo-Rosich [not available]"
    },
]


let workshopSessions
 = [
    {
        id: 1,
         src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/MHM+2023_Implementing+clinical+tools+in+everyday+practice_RF%26DHL/story.html',
         text: "1. Implementing clinical tools in everyday practice",
         speaker: "Dagny Holle-Lee and Rolf Fronczek"
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/IW_implementing+clinical+tools+in+everyday+practice_RGG-GL/story.html',
        text: "2. Implementing clinical tools in everyday practice",
        speaker: "Raquel Gil-Gouveia and Giorgio Lambru"
    },
    {
        id: 3,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/How+can+we+support+patients+beyond+pharmacological+treatments-+MA+FP/story.html',
        text: "3. How can we support patients beyond pharmacological treatments?",
        speaker: "Francesca Puledda and Messoud Ashina"
    },
    {
        id: 4,
        src: '',
        text: "4. How can we support patients beyond pharmacological treatments?",
        speaker: "Gisela Terwindt and Faisal Amin [not available]"
    },
    {
        id: 5,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/MHM+2023_IW-+Real+World+Evidence+vs+Randomized+Controlled/story.html',
        text: "5. Different types of evidence that contribute to improving clinical practice (RWE vs RCT)",
        speaker: "Peter J Goadsby and Christian Lampl"
    },
    {
        id: 6,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/MHM2023_Markers+for+predicting+migraine+attacks/story.html',
        text: "6. Markers for predicting migraine attacks and responses to anti-CGRP treatment",
        speaker: "Piero Barbanti and Patricia Pozo-Rosich"
    }
]

let migraineManagement = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/Migraine+and+Sleep_RF/story.html',
        text: "Migraine and sleep",
        speaker: "Rolf Fronczek"
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/Neuromodulation_FP+(Published)/story.html',
        text: "Neuromodulation",
        speaker: "Francesca Puledda"
    },
]

let debate = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/Debate_Against_PB+(Published)/story.html',
        text: "Piero Barbanti (against)",
        speaker: ""
    },
    {
        id: 2,
        src: '',
        text: "Gisela Terwindt (for) [not available]",
        speaker: ""
    },
]

let plenary3 = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/Hot+topics_DHL+(Published)/story.html',
        text: "Combination therapy and treatment failure",
        speaker: "Dagny Holle-Lee"
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/Hot+topics_GL+(Published)/story.html',
        text: "Late response to treatment and comorbidities  ",
        speaker: "Giorgio Lambru"
    },
]

let plenary4 = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/Menstruation+and+migraine_RGG/story.html',
        text: "Menstruation and Migraine",
        speaker: "Raquel Gil-Gouveia"
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM-2023-Presentations/Sleep+pathways+and+migraine_RF+(Published)/story.html',
        text: "Sleep pathways and migraine",
        speaker: "Rolf Fronczek"
    },
]
@inject('rootStore')
@observer
class IsLoggedInBanner extends React.Component {
    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.state = { 
            attendedPart1: false,
            optimalMigrainePopup: false,
            selectedFileURL: null,
            showLoading: true,
            isAuthenticated: false,
            hasBookedEventSlots: false,
        }
    }

    async componentDidMount() {
        let hasBookedEventSlots = false;
            let eventsModel = await getAllEvents();
            
            let activeEvents = eventsModel.filter(eventItem => (eventItem.statusId !== data.status.deleted))
            let user = await Auth.currentAuthenticatedUser();

            hasBookedEventSlots = activeEvents.some(event => event.eventTimes
                .some(time => {
                   let hasRegistered = time.registeredUsers?.includes(user.username)
                   
                   return hasRegistered;

                }));
        this.setState({ hasBookedEventSlots, showLoading: false });
    }

    openModal = (type, fileURL) => {
        this.disableScroll()
        this.setState({selectedFileURL: fileURL})
        switch (type) {


            case "optimalMigrainePopup":
                this.setState({

                    optimalMigrainePopup: true

                })
                break;




            default:
                return
        }


    }


    closeModal = (type) => {
        this.enableScroll()
        this.setState({selectedFileURL: null})
        switch (type) {

            case "optimalMigrainePopup":
                this.setState({

                    optimalMigrainePopup: false

                })
                break;


            default:
                return
        }
    }

    getUserName = () => {

        // let title = this.authStore?.user?.attributes?.[`custom:title`];
        // if(title === "Other" || title === 'I prefer not to say'){
        //     title = '';
        // }else{
        //     title += " ";
        // }
        let lastName = this.authStore?.user?.attributes?.[`custom:lastName`];
        let firstName = this.authStore?.user?.attributes?.[`custom:firstName`];
        let fullName = ` ${firstName} ${lastName}`;
        fullName = fullName.includes(undefined) ? null : fullName;
   
        return fullName;
    }
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {

        document.body.style.overflow = 'auto'
    }
    render() {
        let { optimalMigrainePopup, isAuthenticated, hasBookedEventSlots } = this.state;
        let username = this.getUserName();
        let videoUrl = process.env.REACT_APP_ENV == 'production' ? "https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4" : "https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4";
        return ( 
            <div className="" id="Welcome" style={{ background: "#d6d6d8"}}>
                <Banner/>
                <div className="row">
                    <span className={styles.icon1}></span>
                    <span className={styles.icon2}></span>
                </div>
                    <div className="container">
                 
                        <div className="row"  style={{position: "relative"}}>
                            <div className="col-12  text-center">
                            <div className={styles.curve1}> 
                          
                            </div>
                              

                           
                                <ScrollAnimation animateIn="fadeIn">
                                <div style={{ border: "1px solid #1a0a60", padding: "5px", borderRadius: "5px", marginBottom: '30px', marginTop: '30px' }}>
                                    <p className="mb-1 mt-1"><strong>This website is intended for use by Healthcare Professionals only.</strong></p>
                                    <p className="mt-1">     <strong>This promotional meeting is organised and funded by Teva Pharmaceuticals Europe B.V.</strong>
                                    </p>
                                    <p  >

                                        Some sessions may contain product information. <br />For the fremanezumab UK prescribing information and adverse events reporting, please see footer of the page.<br />
                                    </p></div>
                                <h1 className="pt-2 mt-4 mb-4">Welcome {username}, </h1>
                                <p style = {{fontSize: "20px"}}>
                                   Thank you for joining us for Migraine Headache Management (MHM) 2024!<br/>
 
                                   {/* {hasBookedEventSlots 
                                   ? (<><a href="/my-session-slots"><strong>REGISTER NOW</strong></a> to confirm your place in the <strong>breakout sessions</strong>. These sessions are allocated on a first come first served basis and registrations will be closed on <strong>Tuesday 19 September.</strong></>)
                                    : (<><a href="/book-session-slots"><strong>REGISTER NOW</strong></a> to confirm your place in the <strong>breakout sessions</strong>. These sessions are allocated on a first come first served basis and registrations will be closed on <strong>Tuesday 19 September.</strong></>)} */}
                                </p>

                                {/* <h3 className="pt-2 mt-4 mb-4">We enjoyed hosting you this year in Prague for MHM 2023.</h3>
                                    <p style={{ fontSize: "19px" }}>
                                        Please see below for linked presentations from the meeting where speakers<br/>
                                        have provided consent to share their content. This content is for educational
                                        purposes only.<br/> Please do not distribute this content.
                                    </p> */}


               {/* <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center custom_list">
                            <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 ">
                                            <h3>Plenary 1: Latest advances in everyday practice</h3>
                                            <ul className="text-center">
                                                {plenary.map(expertForum => {
                                                    return (
                                                        <p key={expertForum.id}>
                                                          {expertForum.src === "" ? 
                                                            (<>{expertForum.text} – {expertForum.speaker}</>) : 
                                                            (<><a onClick={() => this.openModal("optimalMigrainePopup", expertForum.src)}> {expertForum.text}</a> – {expertForum.speaker}</>)}
                                                            
                                                        </p>
                                                    )
                                                })}
                                              
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center custom_list">
                            <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 ">
                                            <h3>Interactive workshop sessions</h3>
                                            <ul className="text-center">
                                                {workshopSessions.map(expertForum => {
                                                    return (
                                                        <p key={expertForum.id}>
                                                          {expertForum.src === "" ? 
                                                            (<>{expertForum.text} <br/> {expertForum.speaker}</>) : 
                                                            (<><a onClick={() => this.openModal("optimalMigrainePopup", expertForum.src)}> {expertForum.text}</a> <br/> {expertForum.speaker}</>)}
                                                            
                                                        </p>
                                                    )
                                                })}
                                              
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center custom_list">
                            <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 ">
                                            <h3>Cutting-edge research progressing migraine management</h3>
                                            <ul className="text-center">
                                                {migraineManagement.map(expertForum => {
                                                    return (
                                                        <p key={expertForum.id}>
                                                            <a onClick={() => this.openModal("optimalMigrainePopup", expertForum.src)}> {expertForum.text}</a> – {expertForum.speaker}
                                                        </p>
                                                    )
                                                })}
                                              
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center custom_list">
                            <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 ">
                                            <h3>Debate: The best approach to managing medication overuse is detoxification</h3>
                                            <ul className="text-center">
                                                {debate.map(expertForum => {
                                                    return (
                                                        <p key={expertForum.id}>
                                                          {expertForum.src === "" ? 
                                                            (<>{expertForum.text}</>) : 
                                                            (<><a onClick={() => this.openModal("optimalMigrainePopup", expertForum.src)}> {expertForum.text}</a></>)}
                                                            
                                                        </p>
                                                    )
                                                })}
                                              
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center custom_list">
                            <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 ">
                                            <h3>Plenary 3 – Hot topics in practical migraine management</h3>
                                            <ul className="text-center">
                                                {plenary3.map(expertForum => {
                                                    return (
                                                        <p key={expertForum.id}>
                                                            <a onClick={() => this.openModal("optimalMigrainePopup", expertForum.src)}> {expertForum.text}</a> – {expertForum.speaker}
                                                        </p>
                                                    )
                                                })}
                                              
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center custom_list">
                            <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 ">
                                            <h3>Plenary 4 – Treating the whole patient: More than just managing migraine</h3>
                                            <ul className="text-center">
                                                {plenary4.map(expertForum => {
                                                    return (
                                                        <p key={expertForum.id}>
                                                            <a onClick={() => this.openModal("optimalMigrainePopup", expertForum.src)}> {expertForum.text}</a> – {expertForum.speaker}
                                                        </p>
                                                    )
                                                })}
                                              
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OptimalMigraine closeModal={() => this.closeModal("optimalMigrainePopup")} isActive={optimalMigrainePopup} fileURL={this.state.selectedFileURL} />
               </div>

               <p style={{ marginBottom: '30px', marginTop: '30px' }}>*<em>Not all gepants are currently approved for the treatment of migraine by the EMA.</em></p> */}


                               
                                <p>
                                    <strong>MHM 2024: Patients, Treatments, Choices</strong> will take place across{" "}
                                    <strong>two half-days </strong>and will provide you with a
                                    space to:
                                </p>                                
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-9 ">
                                    <ul className="text-left">
                                        <li>
                                            Gain expert insights on recent advances in the field
                                        </li>
                                        <li>
                                            Learn how the latest evidence can improve practice and inform decision-making
                                        </li>
                                        <li>
                                            Exchange ideas with colleagues and participate in interactive breakout sessions 
                                        </li>
                                        <li>
                                            Explore different approaches to common clinical scenarios
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-9 text-left">
                                    
                                    <p style={{ marginTop: "10px" }}>
                                        <strong>
                                        <span style={{ fontSize: "25px" }}>Date: Friday 4 and Saturday 5 October 2024</span><br />
                                        Location: JW Marriott Hotel Berlin, Stauffenbergstraße 26, 10785 Berlin, Germany<br />
                                        </strong>
                                        <strong>Parking: </strong>
                                        The Hotel offers an underground garage on property with more than 200 parking spots.
                                    </p>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19426.241660826156!2d13.3525637!3d52.5102679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a851fe88220039%3A0xf83e03f9a70b3368!2sJW%20Marriott%20Hotel%20Berlin!5e0!3m2!1sen!2ske!4v1713438787828!5m2!1sen!2ske"
                                        style={{ border: 0, width: "100%", minHeight: "300px", marginBottom: "20px" }}
                                        allowfullscreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Location Map"
                                    ></iframe>
                              

                                    </div>
                                </div>
             
                                {/* <video controls width="60%" poster={Poster} onPlay={() => trackHomepageVideoFunctions(videoUrl, "play", true)} onEnded={()=> trackHomepageVideoFunctions(videoUrl, "end", true)} onPause={()=> trackHomepageVideoFunctions(videoUrl, "pause", true)}>
                                    <source src={ process.env.REACT_APP_ENV == 'production' ? "https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4" : "https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4"} type="Video/mp4" />
                                </video> */}
                                </ScrollAnimation>
                              
                            </div>
                        </div>
                   
                    
                    </div>
            </div>

         );
    }
}
 
export default IsLoggedInBanner;