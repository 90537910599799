import React from 'react';
import styles from './banner.module.css'
import {Auth} from "aws-amplify";
import {getAllEvents} from "../../../../controllers/events-controller"
import {data} from "../../../../data/data"
import bannerImg from '../../../../media/images/mhm2024/MHM_logo_web.f640ebda.png'
import mobileBannerImg from '../../../../media/images/mhm2024/MHM_logo_web.f640ebda.png'
import { RouterLink, Router, Link } from 'mobx-state-router';
import { inject, observer } from "mobx-react";

@inject("rootStore")
@observer
class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            isAuthenticated: false,
            hasBookedEventSlots: false,
            showLoading: true
          }
    }
      async componentDidMount() {
        let { authStore } = this.props.rootStore;
        let isAuthenticated = await authStore.isAuthenticated();
        let hasBookedEventSlots = false;
        if(isAuthenticated){
            var eventsModel = await getAllEvents();
            this.props.rootStore.contentStore.addEvents(eventsModel);
            
            var activeEvents = eventsModel.filter(eventItem => (eventItem.statusId !== data.status.deleted))
            let user = await Auth.currentAuthenticatedUser();

            hasBookedEventSlots = activeEvents.some(event => event.eventTimes
                .some(time => {
                   var hasRegistred = time.registeredUsers?.includes(user.username)
                   if(hasRegistred){
                   }
                   return hasRegistred;

                }));
        }
        this.setState({ isAuthenticated, hasBookedEventSlots, showLoading: false });
    }
    render() {  
        let { isAuthenticated, hasBookedEventSlots, showLoading } = this.state;
        return ( 
            <div className={styles.main_banner}>
                
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                        <img alt="banner image" className={styles.hide_for_small} src={bannerImg} />
                        <img alt="banner Img" className={styles.hide_for_large} src={mobileBannerImg} />
                    </div>
                        {!showLoading &&
                            <div className="col-12 col-md-6 col-lg-6">
                                {!isAuthenticated &&  <RouterLink className={`${styles.button} button`} routeName="Contact"> CONTACT US TO REGISTER</RouterLink>}
                                 {isAuthenticated &&  hasBookedEventSlots &&
                                    <RouterLink className={`${styles.button} button`} routeName="myProfile">My sessions</RouterLink>
                                }
                               
                            </div> 
                        }
                    </div>
                    {/* Book Session Slots */}
                  
            </div>
         );
    }
}
 
export default Banner;